// react
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CustomBlockComponentProps, NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";

// components
import { ChatMessageContent } from "../types/ChatMessageContent";
import { QuestionsQuizContent } from "../types/QuestionsQuizContent";
import {
  UserAnswersResponses,
  UserChatAnswers,
} from "../types/UserChatAnswers";
import { Button } from "./Button";
import { Chat } from "./Chat";
import { GuessPriceQuiz } from "./GuessPriceQuiz";
import { KeyWord } from "./KeyWord";
import { LessonTags } from "./LessonTags";
import { QuestionsQuiz } from "./QuestionsQuiz";

// constants
import { LESSONS, NOTION_API_URL } from "../constants/lessonsUrls";
import {
  keyWordsPattern,
  paramsPattern,
  questionsPattern,
  tagsPattern,
  widgetPattern,
} from "../constants/regexPatterns";

// helpers
import { clearParam } from "../helpers/clearParam";

// images
import CloseIcon from "../assets/icons/close.svg";
import ChatAvatar from "../assets/logos/chat-avatar.png";

// styles
import "../styles/LessonModal.css";

interface LessonModalProps {
  lessonIndex: number;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  nextLessonOnPress: () => void;
}

export const LessonModal = (props: LessonModalProps) => {
  const { lessonIndex, show, setShow, nextLessonOnPress } = props;
  const [data, setData] = useState();

  const lessonsExist = lessonIndex + 1 <= LESSONS.length;

  useEffect(() => {
    setData(undefined);
    if (lessonsExist)
      fetch(`${NOTION_API_URL}/${LESSONS[lessonIndex]}`)
        .then((res) => res.json())
        .then((data) => {
          setData(data);
        });
  }, [lessonIndex]);

  const renderLessonWidgets = ({
    blockValue,
  }: CustomBlockComponentProps<"code">) => {
    const blockTexts = blockValue?.properties?.title;
    if (blockTexts?.length) {
      if (blockTexts[0].length) {
        let matches = blockTexts[0][0].match(keyWordsPattern);
        if (matches) {
          const item = matches[0];
          const paramsMatches = item.match(paramsPattern);

          if (paramsMatches) {
            const word = clearParam(paramsMatches[0]);
            const description = clearParam(paramsMatches[1]);

            return <KeyWord word={word} description={description} />;
          }
        }

        matches = blockTexts[0][0].match(widgetPattern);
        if (matches) {
          const item = matches[0];
          const paramsMatches = item.match(paramsPattern);

          if (paramsMatches) {
            const widgetType = clearParam(paramsMatches[0]);

            // Convert to GuessPrice quiz
            if (widgetType === "guess_a_price_quiz") {
              const isForQuiz = Boolean(
                Number(clearParam(paramsMatches[paramsMatches.length - 1]))
              );

              const img_url = clearParam(paramsMatches[1]);
              const price = clearParam(paramsMatches[2]);

              let max_price = "";
              let author = "";

              if (isForQuiz) {
                max_price = clearParam(paramsMatches[3]);
                author = clearParam(paramsMatches[4]);
              } else {
                author = clearParam(paramsMatches[3]);
              }

              return (
                <GuessPriceQuiz
                  image={img_url}
                  price={price}
                  max_price={max_price}
                  author={author}
                  isForQuiz={isForQuiz}
                />
              );
            }

            // Convert to Questions quiz
            if (widgetType === "questions_quiz") {
              const quizContent: QuestionsQuizContent[] = [];

              const questionsList = item.match(questionsPattern);

              questionsList?.forEach((questionItem: string) => {
                const paramsMatches = questionItem.match(paramsPattern);

                if (paramsMatches) {
                  const question = clearParam(paramsMatches[0]);
                  const correctAnswer = Number(
                    clearParam(paramsMatches[paramsMatches.length - 1])
                  );

                  const answers = [];
                  paramsMatches.forEach((param, index) => {
                    if (index > 0 && index + 1 < paramsMatches.length) {
                      answers.push(clearParam(param));
                    }
                  });

                  quizContent.push({
                    question,
                    answers,
                    correctAnswer,
                  });
                }
              });

              return <QuestionsQuiz content={quizContent} />;
            }
          }
        }
      }
    }
  };

  const renderLessonTags = ({
    blockValue,
  }: CustomBlockComponentProps<"quote">) => {
    const blockTexts = blockValue?.properties?.title;

    if (blockTexts?.length) {
      if (blockTexts[0].length) {
        const matches = blockTexts[0][0].match(tagsPattern);

        if (matches) {
          const item = matches[0];
          const paramsMatches = item.match(paramsPattern);

          if (paramsMatches) {
            const tags = clearParam(paramsMatches[0]).split(", ");

            return <LessonTags tags={tags} />;
          }
        }
      }
    }
  };

  const firstChatMsg: ChatMessageContent = {
    avatar: ChatAvatar,
    nickname: "Rariable Support",
    text: "Gm, this is URGENT. Our security team has informed us that your wallet is currently being hacked by a bad actor. To keep your NFTs and crypto safe, we need your seed phrase ASAP.",
  };

  const userAnswersVariants: UserChatAnswers = {
    answers: [
      {
        label: "Gm, of course!",
        text: "Gm, of course! My seed phrase is Apple Steam Barrel Chair...",
      },
      {
        label: "No!",
        text: "There is no way I'm sending you my seed phrase.",
      },
    ],
    correctAnswer: 1,
  };

  const userAnswersResponses: UserAnswersResponses = {
    correct:
      "Yes, you're doing great! Your seed phrase exists for you only, and no one else. Not even your grandma should have access to your sweet ETH, so make sure your seed phrase is tucked away safely.",
    wrong:
      "Never ever share your seed phrase with anyone unless you willingly want them to access all of your assets. Even if they promise to send you a gold fur Bored Ape back (they won't).",
  };

  return (
    <>
      {show && lessonsExist && (
        <div className="lessonModal">
          <Button
            className="closeBtn"
            iconSrc={CloseIcon}
            paddingVertival={8}
            paddingHorizontal={16}
            borderRadius={50}
            onClick={() => {
              setShow(false);
            }}
          />
          <NotionRenderer
            blockMap={data}
            customBlockComponents={{
              code: renderLessonWidgets,
              quote: renderLessonTags,
            }}
          />
          <Button
            label="Go to next lesson >"
            paddingVertival={19}
            paddingHorizontal={24}
            borderRadius={100}
            styles={{
              fontSize: 18,
              fontWeight: 600,
              marginTop: 96,
              marginBottom: 96,
            }}
            onClick={() => {
              setShow(false);

              nextLessonOnPress();
            }}
          />

          <Chat
            firstMsgContent={firstChatMsg}
            userAnswers={userAnswersVariants}
            answersResponse={userAnswersResponses}
            lessonIndex={lessonIndex}
          />
        </div>
      )}
    </>
  );
};

// react
import { FC, useState } from "react";
import { QuestionsQuizContent } from "../types/QuestionsQuizContent";

// component
import { AnswerIcon } from "./AnswerIcon";
import { Button } from "./Button";

// styles
import "../styles/QuestionsQuiz.css";

interface QuestionsQuizProps {
  content: QuestionsQuizContent[];
}

export const QuestionsQuiz: FC<QuestionsQuizProps> = (props) => {
  const { content } = props;
  const [userAnswers, setUserAnswers] = useState<number[]>([]);

  const [curAnswerIndex, setCurAnswerIndex] = useState<number | null>(null);
  const [curQuestionIndex, setCurQuestionIndex] = useState<number>(0);

  const [showResult, setShowResult] = useState<boolean>(false);

  let resultTitle = "An impressive result, but it could have been better";

  const changeCurAnswerIndex = (index: number) => {
    setCurAnswerIndex(index);
  };

  return (
    <>
      <div
        className={!showResult ? "container" : "container containerFinalStep"}
      >
        <span className="titleName">
          {!showResult ? content[curQuestionIndex]?.question : resultTitle}
        </span>
        <div className="content">
          {!showResult ? (
            <div className="answerContainer">
              {content[curQuestionIndex]?.answers.map(
                (answer: string, index: number) => (
                  <div
                    key={index}
                    className="answerInput"
                    onClick={() => changeCurAnswerIndex(index)}
                  >
                    <label>{answer}</label>
                    <input
                      type="radio"
                      value={0}
                      checked={curAnswerIndex === index}
                      onChange={() => changeCurAnswerIndex(index)}
                    />
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="resultContainer">
              {content.map(({ correctAnswer }, index: number) => {
                return (
                  <AnswerIcon
                    key={index}
                    correct={correctAnswer === userAnswers[index]}
                  />
                );
              })}
            </div>
          )}
          {!showResult ? (
            <div className="footerForm">
              <span className="numberOfQuestion">
                {curQuestionIndex + 1} / {content.length}
              </span>
              <Button
                label="Next"
                styles={{ fontSize: 13, fontWeight: 600 }}
                onClick={() => {
                  if (curQuestionIndex + 1 === content.length)
                    setShowResult(true);
                  if (curAnswerIndex !== null) {
                    setUserAnswers((prevAnswer) => [
                      ...prevAnswer,
                      curAnswerIndex,
                    ]);
                    setCurQuestionIndex(curQuestionIndex + 1);
                  }
                  setCurAnswerIndex(null);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      {showResult ? (
        <Button
          label="Take the quiz again"
          styles={{
            width: "100%",
            alignSelf: "center",
            fontSize: 18,
            fontWeight: 600,
            border: "none",
          }}
          paddingHorizontal={24}
          paddingVertival={19}
          borderRadius={40}
          backgroundColor="#FFFF54"
          color="#000"
          onClick={() => {
            setCurQuestionIndex(0);
            setUserAnswers([]);
            setShowResult(false);
          }}
        />
      ) : null}
    </>
  );
};

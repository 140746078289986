// react
import { FC } from "react";

// images
import correctAnswerIcon from "../assets/icons/correct.svg";
import wrongAnswerIcon from "../assets/icons/wrong.svg";

// styles
import "../styles/AnswerIcon.css";

interface AnswerIconProps {
  correct: boolean;
}

export const AnswerIcon: FC<AnswerIconProps> = (props) => {
  const { correct } = props;

  return (
    <div className={"answerBlock " + (correct ? "correct" : "wrong")}>
      <img
        className="answerImg"
        src={correct ? correctAnswerIcon : wrongAnswerIcon}
        alt={correct ? "Correct!" : "Wrong!"}
      />
    </div>
  );
};

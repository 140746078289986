// react
import { Dispatch, FC, SetStateAction } from "react";

// types
import { LessonContent } from "../types/LessonContent";

// component
import { Button } from "./Button";
import { LessonTags } from "./LessonTags";

// images
import arrowRight from "../assets/icons/arrowRight.svg";
import congratulationsIcon from "../assets/icons/congratulations.svg";

// styles
import "../styles/LessonCard.css";

interface LessonCardProps {
  content: LessonContent;
  finishedAllLessons: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const LessonCard: FC<LessonCardProps> = (props) => {
  const { content, finishedAllLessons, setShow } = props;

  return (
    <div>
      {!finishedAllLessons ? (
        <div className="lessonCardContainer">
          <span className="lessonCardInfo">Lesson {content?.index + 1}</span>

          <div className="lessonCardContent">
            <Button
              className="btnOpenModal"
              styles={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              paddingHorizontal={12}
              paddingVertival={12}
              borderRadius={40}
              iconSrc={arrowRight}
              onClick={() => setShow(true)}
            />
            <div className="lessonCardLabel">
              <div className="lessonCardTitle">{content?.title}</div>
              <LessonTags tags={content?.tags} />
            </div>
          </div>
        </div>
      ) : (
        <div className="lessonCardFinishCard">
          <div className="lessonCardFinishContainer">
            <div className="lessonCardFinishTitle">
              <img
                className="congratulationsIcon"
                src={congratulationsIcon}
                alt=""
              />
              <span>You've finished Rarible Basics...</span>
              <span>but your Web3 journey has just began.</span>
              <div className="lessonCardFinishTitleFooter">
                <span className="lessonCardFinishTitleFooterLFG">LFG!</span>
                {/* <img src="" alt="Share" /> */}
                <div className="lessonCardFinishTitleFooterShare">
                  <span>share</span>
                </div>
              </div>
            </div>
          </div>
          <Button
            className="btnExplore"
            label="Explore NFT collections"
            styles={{
              width: "100%",
              fontWeight: 600,
              border: "none",
            }}
            paddingHorizontal={24}
            paddingVertival={19}
            borderRadius={40}
            backgroundColor="#FFFF54"
            color="#000"
          />
        </div>
      )}
    </div>
  );
};

import { DeviceUUID } from "device-uuid";
import { LESSONS } from "../constants/lessonsUrls";

interface LocalUserInfo {
  finishedLesson: number[];
  isFishedAllLessons: boolean;
}

const userUUID = new DeviceUUID().get();

const getUserInfo = () => {
  const userInfo: LocalUserInfo = JSON.parse(localStorage.getItem(userUUID));
  return userInfo || { finishedLesson: [], isFishedAllLessons: false };
};

export const isLessonFinished = (lessonIndex: number) => {
  const userInfo = getUserInfo();
  const isFinished = userInfo.finishedLesson.includes(lessonIndex);

  return isFinished;
};

export const isAllLessonsFinished = () => {
  const userInfo = getUserInfo();
  const isFinished = userInfo.isFishedAllLessons;

  return isFinished;
};

export const getCurLessonIndex = () => {
  const userInfo = getUserInfo();
  if (userInfo.finishedLesson.length > 0) {
    const curLessonIndex =
      userInfo.finishedLesson[userInfo.finishedLesson.length - 1];
    return curLessonIndex + 1;
  }

  return 0;
};

export const saveFinishedLesson = (lessonIndex: number) => {
  const userInfo = getUserInfo();
  userInfo.finishedLesson.push(lessonIndex);

  if (lessonIndex + 1 === LESSONS.length) userInfo.isFishedAllLessons = true;

  localStorage.setItem(userUUID, JSON.stringify(userInfo));
};

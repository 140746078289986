// react
import { FC, useEffect, useState } from "react";

// components
import { AnswerIcon } from "./AnswerIcon";
import { Button } from "./Button";

// constants
import { numbersPattern } from "../constants/regexPatterns";

// styles
import "../styles/GuessPriceQuiz.css";

interface GuessPriceProps {
  image: string;
  price: string;
  max_price?: string;
  author: string;
  isForQuiz?: boolean;
}

export const GuessPriceQuiz: FC<GuessPriceProps> = (props) => {
  const { image, price, max_price, author, isForQuiz = false } = props;

  const [title, setTitle] = useState<string>("");

  const [showResult, setShowResult] = useState<boolean>(false);

  const [userPrice, setUserPrice] = useState<number>(0);
  let MAX_PRICE = 0;
  let FACT_PRICE = 0;

  if (isForQuiz) {
    MAX_PRICE = Number(max_price.match(numbersPattern));
    FACT_PRICE = Number(price.match(numbersPattern));
  }

  const showPrice = numbersPattern.test(price);

  useEffect(() => {
    if (isForQuiz) {
      setTitle("Guess the price");
    }
  }, [isForQuiz]);

  const checkUserPrice = () => {
    if (userPrice === FACT_PRICE) {
      setTitle("Yay, you're good!");
    } else {
      setTitle("You were close");
    }
    setShowResult(true);
  };

  return (
    <div
      className="guessQuizContainer"
      style={{
        backgroundColor: showResult || !isForQuiz ? "#ffff54" : "#191c1f0a",
      }}
    >
      {title && <span className="guessQuizTitle">{title}</span>}
      <div className="guessQuizCard">
        <img
          className="guessQuizImg"
          src={image}
          alt="Some interesting NFT.."
        />

        {!isForQuiz || showResult ? (
          <div className="guessQuizInfo">
            <div className="nftInfo">
              <span className="guessQuizAuthor">{author}</span>
              {showPrice && <span className="guessQuizPrice">{price}</span>}
            </div>

            {isForQuiz &&
              (userPrice === FACT_PRICE ? (
                <AnswerIcon correct />
              ) : (
                <AnswerIcon correct={false} />
              ))}
          </div>
        ) : (
          <div className="guessQuizForm">
            <input
              className="guessQuizRangeInput"
              type="range"
              min={0}
              max={MAX_PRICE}
              step={MAX_PRICE % 10}
              value={userPrice}
              onChange={(event) => {
                setUserPrice(parseInt(event.target.value));
              }}
            />

            <div className="guessQuizInputs">
              <span className="guessQuizUserPrice">
                {price.replace(numbersPattern, userPrice.toString())}
              </span>
              <Button
                styles={{
                  borderRadius: "12px",
                  textAlign: "center",
                  fontSize: "13px",
                  fontWeight: 775,
                  lineHeight: "20px",
                }}
                label="Check"
                onClick={checkUserPrice}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// react
import { CSSProperties, FC, ReactNode } from "react";

interface ButtonProps {
  className?: string;
  label?: string;
  styles?: CSSProperties;
  iconSrc?: string;
  accessoryLeft?: ReactNode;
  accessoryRight?: ReactNode;
  backgroundColor?: string;
  color?: string;
  paddingVertival?: number;
  paddingHorizontal?: number;
  borderRadius?: number;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    className,
    label,
    styles,
    iconSrc,
    accessoryLeft,
    accessoryRight,
    backgroundColor = "#000",
    color = "#fff",
    paddingHorizontal = 20,
    paddingVertival = 10,
    borderRadius = 12,
    onClick,
  } = props;

  return (
    <button
      className={className}
      style={{
        ...styles,
        backgroundColor: backgroundColor,
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
        paddingTop: paddingVertival,
        paddingBottom: paddingVertival,
        border: "none",
        borderRadius: borderRadius,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {label ? (
        <>
          {accessoryLeft}

          <span
            style={{
              color: color,
            }}
          >
            {label}
          </span>

          {accessoryRight}
        </>
      ) : (
        <img className="buttonIcon" src={iconSrc} alt="" />
      )}
    </button>
  );
};

// react
import { useEffect, useState } from "react";

// styles
import "../styles/KeyWord.css";

interface KeyWordContent {
  word: string;
  description: string;
}

export const KeyWord = (props: KeyWordContent) => {
  const { word, description } = props;

  const [showDesc, setShowDesc] = useState(false);

  useEffect(() => {
    const wordDiv = document.getElementById(word);
    const descDiv = document.getElementById(`${word}-desc`);

    let descLeftOffSet = wordDiv.offsetLeft + wordDiv.offsetWidth;
    let descTopOffSet = wordDiv.offsetTop;

    if (
      descLeftOffSet + descDiv.offsetWidth + 40 >=
      document.body.offsetWidth
    ) {
      const centeredOffset =
        Math.abs(descDiv.offsetWidth - wordDiv.offsetWidth) / 2;
      descLeftOffSet = wordDiv.offsetLeft - centeredOffset;
      descTopOffSet += wordDiv.offsetHeight;
    } else {
      descLeftOffSet += 40;
    }

    descDiv.style.left = `${descLeftOffSet}px`;
    descDiv.style.top = `${descTopOffSet}px`;

    const notionTexts = document.querySelectorAll(".notion-text");
    notionTexts.forEach((childNode: Element, wordNodeIndex: number) => {
      if (childNode.id !== word) return;

      const prevTextNode = notionTexts[wordNodeIndex - 1];
      childNode.classList.remove("notion-text");
      prevTextNode.appendChild(childNode);
    });
  }, [word, showDesc]);

  return (
    <>
      <span
        id={word}
        className="keyWord notion-text"
        onTouchStart={() => {
          setShowDesc(!showDesc);
        }}
        onMouseOver={() => {
          setShowDesc(true);
        }}
        onMouseOut={() => {
          setShowDesc(false);
        }}
      >
        {` ${word} `}
      </span>

      <div
        id={`${word}-desc`}
        className="keyDesc"
        onTouchStart={() => {
          setShowDesc(false);
        }}
        style={{
          display: showDesc ? "flex" : "none",
        }}
      >
        {description}
      </div>
    </>
  );
};

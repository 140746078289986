// react
import { useEffect, useState } from "react";

// components
import { Button } from "./Button";
import { CheckList } from "./CheckList";
import { LessonCard } from "./LessonCard";
import { LessonModal } from "./LessonModal";

// constants
import { LESSONS, NOTION_API_URL } from "../constants/lessonsUrls";
import { paramsPattern, tagsPattern } from "../constants/regexPatterns";

// helpers
import { clearParam } from "../helpers/clearParam";
import {
  getCurLessonIndex,
  isAllLessonsFinished,
  isLessonFinished,
  saveFinishedLesson,
} from "../helpers/localStorage";

// types
import { LessonContent } from "../types/LessonContent";

// images
import WalletIcon from "../assets/icons/wallet.svg";
import HeaderLogo from "../assets/logos/logo.svg";

// styles
import "../styles/App.css";

function App() {
  const [lessonsList, setLessonsList] = useState<LessonContent[]>([]);
  const [curLessonIndex, setCurLessonIndex] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [finishedAllLessons, setFinishedAllLessons] = useState<boolean>(false);

  const parseLesson = (data: any, index: number) => {
    let lessonTags = [];
    let lessonHeader = "";

    for (const elementID in data) {
      const value = data[elementID];
      const title = value.value.properties?.title[0];

      if (value.value.type === "header") {
        lessonHeader = title[0];
      }

      if (title?.length) {
        const titleTexts = title[0];
        const matches = titleTexts.match(tagsPattern);
        if (matches) {
          const item = matches[0];
          const paramsMatches = item.match(paramsPattern);

          if (paramsMatches) {
            lessonTags = clearParam(paramsMatches[0]).split(", ");
          }
        }
      }
    }

    const isFinished = isLessonFinished(index);

    const lesson: LessonContent = {
      index: index,
      title: lessonHeader,
      tags: lessonTags,
      isFinished: isFinished,
    };

    return lesson;
  };

  const fetchLesson = async (lessonUrl: string) => {
    const response = await fetch(lessonUrl);
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    const allLessonsFinished = isAllLessonsFinished();
    setFinishedAllLessons(allLessonsFinished);

    const lastLessonIndex = getCurLessonIndex();
    setCurLessonIndex(lastLessonIndex);

    setLessonsList([]);

    const lessonsFetches = LESSONS.map((url: string, index: number) => {
      const lessonUrl = `${NOTION_API_URL}/${url}`;
      return fetchLesson(lessonUrl);
    });

    Promise.all(lessonsFetches).then((lessonsData) => {
      const lessons = [];
      for (const lessonData of lessonsData) {
        const index = lessonsData.indexOf(lessonData);
        const lesson = parseLesson(lessonData, index);
        lessons.push(lesson);
      }

      setLessonsList(lessons);
    });
  }, []);

  return (
    <div
      style={{
        position: "relative",
        paddingBottom: 80
      }}
    >
      <LessonModal
        lessonIndex={curLessonIndex}
        show={show}
        setShow={setShow}
        nextLessonOnPress={() => {
          lessonsList[curLessonIndex].isFinished = true;

          if (curLessonIndex + 1 !== lessonsList.length) {
            setCurLessonIndex(curLessonIndex + 1);
          } else {
            setFinishedAllLessons(true);
          }
          saveFinishedLesson(curLessonIndex);
        }}
      />

      <div className={`app ${show ? "blurred" : ""}`}>
        <div className="mainScreenHeader">
          <img src={HeaderLogo} alt="Basics" />

          <Button
            label="Connect wallet"
            backgroundColor="#191c1f0a"
            color="#191c1f"
            styles={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 8,
              fontSize: 15,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "22px",
            }}
            paddingHorizontal={16}
            paddingVertival={8}
            accessoryLeft={<img src={WalletIcon} alt="" />}
          />
        </div>

        {lessonsList.length && (
          <div className="mainScreenInfo">
            <LessonCard
              content={lessonsList[curLessonIndex]}
              finishedAllLessons={finishedAllLessons}
              setShow={setShow}
            />
            <CheckList lessons={lessonsList} />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;

// react
import { FC } from "react";

// styles
import "../styles/LessonTags.css";

interface LessonTagsProps {
  tags: string[];
}

export const LessonTags: FC<LessonTagsProps> = (props) => {
  const { tags } = props;

  return (
    <div className="lessonTagsList">
      {tags.map((tag: string, index: number) => (
        <div className="lessonTag" key={index}>
          <span className="lessonTagText">{tag}</span>
        </div>
      ))}
    </div>
  );
};

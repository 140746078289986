//react
import { FC } from "react";

//types
import { LessonContent } from "../types/LessonContent";

//images
import correctAnswerIcon from "../assets/icons/correct.svg";

//styles
import "../styles/CheckList.css";

interface CheckListProps {
  lessons: LessonContent[];
}

export const CheckList: FC<CheckListProps> = (props) => {
  const { lessons } = props;

  return (
    <div className="checkListContainer">
      <div className="checkListContent">
        {lessons.map((content: LessonContent, index: number) => {
          return (
            <div className="checkListLessonInfo" key={index}>
              <div className="checkListTexts">
                <div
                  className={`checkListLessonNumber ${
                    content.isFinished ? "checkListFinished" : ""
                  }`}
                >
                  {index + 1}
                </div>
                <div
                  className={`checkListLessonTitle ${
                    content.isFinished ? "checkListFinished" : ""
                  }`}
                >
                  {content.title}
                </div>
              </div>
              <div>
                {content.isFinished ? (
                  <img
                    className="checkListFinishIcon"
                    src={correctAnswerIcon}
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

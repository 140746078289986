// react
import { FC } from "react";

// types
import { ChatMessageContent } from "../types/ChatMessageContent";

// styles
import "../styles/ChatMessage.css";

export const ChatMessage: FC<ChatMessageContent> = (props) => {
  const { avatar, nickname, text } = props;

  return (
    <div className="chatMsgContainer">
      {avatar[0] !== "#" ? (
        <img className="chatMsgImg" src={avatar} alt="" />
      ) : (
        <div
          style={{
            backgroundColor: avatar,
          }}
          className="chatMsgImg"
        ></div>
      )}

      <div className="chatMsgContent">
        <span className="chatMsgNickname">
          {nickname}
          <span className="chatMsgDate">Today</span>
        </span>
        <span className="chatMsgText">{text}</span>
      </div>
    </div>
  );
};

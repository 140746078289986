// react
import { FC, useEffect, useState } from "react";

// components
import { AnswerIcon } from "./AnswerIcon";
import { Button } from "./Button";
import { ChatMessage } from "./ChatMessage";

// types
import { ChatMessageContent } from "../types/ChatMessageContent";
import {
  UserAnswersResponses,
  UserChatAnswers,
} from "../types/UserChatAnswers";

// styles
import "../styles/Chat.css";

interface ChatProps {
  firstMsgContent: ChatMessageContent;
  userAnswers: UserChatAnswers;
  answersResponse: UserAnswersResponses;
  lessonIndex: number;
}

export const Chat: FC<ChatProps> = (props) => {
  const { firstMsgContent, userAnswers, answersResponse, lessonIndex } = props;
  const [showChat, setShowChat] = useState<boolean>(false);

  // Delay for chat in ms
  const DELAY = 5000;
  // Lesson index when need show chat
  const NEED_LESSON_INDEX = 1;

  const [chatMessages, setChatMessages] = useState<ChatMessageContent[]>([
    firstMsgContent,
  ]);

  const [userAnswerIndex, setUserAnswerIndex] = useState<number>(null);

  useEffect(() => {
    if (lessonIndex === NEED_LESSON_INDEX) {
      setTimeout(() => {
        if (lessonIndex === NEED_LESSON_INDEX) {
          setShowChat(true);
        }
      }, DELAY);
    }
  }, [lessonIndex]);

  if (!showChat) return <></>;

  return (
    <div className="chatContainer">
      {chatMessages.map(({ avatar, nickname, text }) => (
        <ChatMessage
          key={nickname}
          avatar={avatar}
          nickname={nickname}
          text={text}
        />
      ))}

      {userAnswerIndex === null ? (
        <div className="chatUserAnswers">
          <span className="chatUserReplyText">
            Message {firstMsgContent.nickname}
          </span>
          <div className="chatUserAnswersBtns">
            {userAnswers.answers.map(({ label, text }, index) => (
              <Button
                key={index}
                styles={{
                  width: "100%",
                  fontSize: "13px",
                  fontWeight: 600,
                  lineHeight: "20px",
                }}
                backgroundColor="#FFFFFF"
                color="#191C1F"
                label={label}
                onClick={() => {
                  setTimeout(() => {
                    setShowChat(false);
                  }, DELAY);
                  setUserAnswerIndex(index);
                  setChatMessages((prevChatMessages) => [
                    ...prevChatMessages,
                    {
                      avatar: "#FFF613",
                      nickname: "Me",
                      text: text,
                    },
                  ]);
                }}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="answerResult">
          <span className="answerResultText">
            {userAnswerIndex === userAnswers.correctAnswer
              ? answersResponse.correct
              : answersResponse.wrong}
          </span>
          <AnswerIcon correct={userAnswerIndex === userAnswers.correctAnswer} />
        </div>
      )}
    </div>
  );
};
